@use "mixins" as *
.notification
  background: var(--account_betsItemBg)
  border: 1px dashed var(--account_betsItemBorder)
  padding: 6px 10px
  display: flex
  justify-content: center
  flex-direction: column
  min-height: 46px
  text-transform: capitalize
  border-radius: 3px
  margin: 20px auto 0
  max-width: 84%
  > h3
    @include bold
    @include flex
    color: rgb(var(--particularColor2))
    text-transform: uppercase
    margin-bottom: 4px

    + span
      line-height: 16px

  &.info
    align-items: center
    margin-top: 100px
  &.error,
  &.warning,
  &.success
    position: relative
    padding: 6px 10px 6px 36px
    :global(.imageNext)
      position: absolute
      left: 10px
      top: 50%
      transform: translateY(-50%)
      margin-right: 10px
